import React, { useState } from "react";
import HcpLayout from "../components/hcp/layout";
import { Row, Col } from "react-grid-system";
import { Seo } from "../components/seo";
import axios from "axios";
import "../sass/_orphan.scss";
import "./survey.scss";
import pageIcon from "../images/survey/survey-icon_open.svg";
import thankYouIcon from "../images/survey/survey-icon_complete.svg";
import landingDesktopImg from "../images/survey/survey-materials.webp";
import step1DesktopImg from "../images/survey/survey-materials-header-desktop.webp";
import step1MobileImg from "../images/survey/survey-materials-header-mobile.webp";
import { Controller, useForm } from "react-hook-form";
import { TfiAngleRight } from "react-icons/tfi";

const ELQ_FORM = "Wakix_Patient_Adherance_Survey_STAGE";
const ELQ_SITE_ID = "1241076867";

const SurveyPage = () => {
    const [step, setStep] = React.useState(0);
    const [data, setData] = React.useState({});

    const {
        handleSubmit: handleSubmit1,
        //  register: register1,
        control: control1,
        formState: { errors: errors1 },
    } = useForm({
        mode: "onBlur",
    });
    const {
        handleSubmit: handleSubmit2,
        register: register2,
        control: control2,
        formState: { errors: errors2 },
    } = useForm({
        mode: "onBlur",
    });

    const handleNextStep = async (nextStep, d) => {
        setData({ ...data, ...d });

        if (nextStep < 3) {
            window.scrollTo(0, 0);
            setStep(nextStep);
            return;
        }

        if (nextStep === 3) {
            await sendPostRequest({ ...data, ...d });
            return;
        }
    };

    const [selectedValues, setSelectedValues] = useState([]);

    const handleCheckboxChange = (e) => {
        const value = e.target.value;
        const isChecked = e.target.checked;
        if (isChecked) {
            setSelectedValues([...selectedValues, value]);
        } else {
            setSelectedValues(selectedValues.filter((val) => val !== value));
        }
    };

    const sendPostRequest = async (obj) => {
        // console.log("data", obj);
        const {
            preparing_doctor_interactions,
            knowing_expectations_taking_wakix,
            making_daily_routines_taking_wakix,
            noticing_goal_progression,
            finding_more_support_managing_narcolepsy,
            frequency_receiving_emails_tools,
            vol,
            FeaturesBox,
            taking_wakix,
        } = obj;
        const dataToSend = {
            elqFormName: ELQ_FORM,
            elqSiteID: ELQ_SITE_ID,
            preparing_doctor_interactions: preparing_doctor_interactions,
            knowing_expectations_taking_wakix:
                knowing_expectations_taking_wakix,
            making_daily_routines_taking_wakix:
                making_daily_routines_taking_wakix,
            noticing_goal_progression: noticing_goal_progression,
            finding_more_support_managing_narcolepsy:
                finding_more_support_managing_narcolepsy,
            frequency_receiving_emails_tools: frequency_receiving_emails_tools,
            likely_to_recommend: vol,
            features_more_useful: FeaturesBox.join(", "),
            taking_wakix: taking_wakix,
        };

        try {
            const response = await axios.post("/crm/survey", dataToSend, {});
            setStep(3);
            window.scrollTo(0, 0);
            // console.log("response");
            // console.log(response);
            return response;
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <HcpLayout
            pageClass="survey-page orphan-page"
            jobCode="US-WAK-2400360/Jul 2024"
            callLinkNeeded={false}
            copyRightYear="2024"
            noStickyHCPLocatorCTA={true}
        >
            <Row>
                <Col xs={12}>
                    <section className="cta-block">
                        <Row>
                            <Col xs={12}>
                                <div className="main-wrapper">
                                    <section
                                        className={`start ${
                                            step === 0 ? "current-step" : ""
                                        }`}
                                    >
                                        <div className="content-wrap">
                                            <div className="page-content">
                                                <div className="centered-content">
                                                    <div className="media">
                                                        <img
                                                            src={pageIcon}
                                                            className="mobile-header-icon"
                                                            alt="Clipboard icon"
                                                        />
                                                        <h1 className="font-40">
                                                            We Value Your
                                                            Opinion
                                                        </h1>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="hero-list">
                                                <div className="centered-content">
                                                    <div className="text-content">
                                                        <p>
                                                            For the past few
                                                            months, you have
                                                            received emails on
                                                            topics such as what
                                                            to expect with
                                                            WAKIX, how to take
                                                            WAKIX, and finding
                                                            support. Some emails
                                                            also included access
                                                            to online resources,
                                                            such as:
                                                        </p>
                                                    </div>
                                                    <Row className="row">
                                                        <Col
                                                            lg={8}
                                                            className="col"
                                                        >
                                                            <div className="list-wrapper">
                                                                <ul className="bulleted">
                                                                    <li>
                                                                        <strong>
                                                                            Talking
                                                                            About
                                                                            Your
                                                                            Treatment
                                                                        </strong>
                                                                        , a
                                                                        discussion
                                                                        guide
                                                                        for
                                                                        talking
                                                                        to your
                                                                        healthcare
                                                                        provider
                                                                    </li>
                                                                    <li>
                                                                        <strong>
                                                                            Setting
                                                                            Your
                                                                            Treatment
                                                                            Goals
                                                                        </strong>
                                                                        , a
                                                                        guide to
                                                                        setting
                                                                        and
                                                                        reflecting
                                                                        on
                                                                        treatment
                                                                        goals
                                                                    </li>
                                                                    <li>
                                                                        <strong>
                                                                            Inspiration
                                                                            Generator
                                                                        </strong>
                                                                        , a tool
                                                                        to
                                                                        create
                                                                        an
                                                                        inspiring
                                                                        image
                                                                        for your
                                                                        phone or
                                                                        computer
                                                                    </li>
                                                                </ul>
                                                                <p>
                                                                    You were
                                                                    also sent an{" "}
                                                                    <strong>
                                                                        Inspiration
                                                                        Journal
                                                                    </strong>{" "}
                                                                    by mail to
                                                                    help you get
                                                                    inspired,
                                                                    practice
                                                                    self-&#x2060;care,
                                                                    and organize
                                                                    your
                                                                    thoughts.
                                                                </p>
                                                                <div className="btn-wrap">
                                                                    <button
                                                                        class="btn btn-primary"
                                                                        onClick={() =>
                                                                            handleNextStep(
                                                                                1,
                                                                                {}
                                                                            )
                                                                        }
                                                                    >
                                                                        Tell us
                                                                        what you
                                                                        think
                                                                        <TfiAngleRight />
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col
                                                            lg={4}
                                                            className="col"
                                                        >
                                                            <div className="hero">
                                                                <img
                                                                    src={
                                                                        landingDesktopImg
                                                                    }
                                                                    alt="Patient tools"
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>
                                        </div>
                                    </section>

                                    <section
                                        class={`step step-1${
                                            step === 1 ? "current-step" : ""
                                        }`}
                                    >
                                        <div class="hero-copy">
                                            <div className="media">
                                                <img
                                                    src={step1DesktopImg}
                                                    alt="survey"
                                                    className="desktop"
                                                />
                                                <img
                                                    src={step1MobileImg}
                                                    alt="survey"
                                                    className="mobile"
                                                />
                                            </div>
                                        </div>
                                        <div class="wrapper">
                                            <div className="centered-content">
                                                <div className="step-counts">
                                                    <h2>Step 1 of 2</h2>
                                                    <p>
                                                        Read the following
                                                        statements and tell us
                                                        how{" "}
                                                        <strong>
                                                            <em>helpful </em>
                                                        </strong>{" "}
                                                        the emails and tools
                                                        were to you as you
                                                        started taking WAKIX.
                                                    </p>
                                                    <div
                                                        className={`${
                                                            Object.keys(errors1)
                                                                .length > 0
                                                                ? "error-show"
                                                                : ""
                                                        }`}
                                                    >
                                                        <p class="error">
                                                            Please make a
                                                            selection.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="content">
                                                <div class="form-content">
                                                    <div className="form-wrapper">
                                                        <div
                                                            className={`question-wrapper gray-bg`}
                                                        >
                                                            <div className="centered-content">
                                                                <Controller
                                                                    control={
                                                                        control1
                                                                    }
                                                                    name="preparing_doctor_interactions"
                                                                    rules={{
                                                                        required: true,
                                                                    }}
                                                                    render={({
                                                                        field: {
                                                                            onChange,
                                                                            value,
                                                                        },
                                                                    }) => (
                                                                        <div
                                                                            class="radio_set"
                                                                            onChange={(
                                                                                e
                                                                            ) =>
                                                                                onChange(
                                                                                    e
                                                                                        .target
                                                                                        .value
                                                                                )
                                                                            }
                                                                        >
                                                                            <h2 class="question">
                                                                                Preparing
                                                                                me
                                                                                for
                                                                                interactions
                                                                                with
                                                                                my
                                                                                healthcare
                                                                                provider
                                                                                about
                                                                                WAKIX.
                                                                            </h2>
                                                                            <fieldset class="radio">
                                                                                <label for="pdi-1">
                                                                                    <input
                                                                                        type="radio"
                                                                                        id="pdi-1"
                                                                                        name="preparing_doctor_interactions"
                                                                                        group=""
                                                                                        value="extremely helpful"
                                                                                    />
                                                                                    <p class="noselect">
                                                                                        <span class="radio_span"></span>
                                                                                        Extremely
                                                                                        helpful
                                                                                    </p>
                                                                                </label>
                                                                            </fieldset>
                                                                            <fieldset class="radio">
                                                                                <label for="pdi-2">
                                                                                    <input
                                                                                        type="radio"
                                                                                        id="pdi-2"
                                                                                        name="preparing_doctor_interactions"
                                                                                        group=""
                                                                                        value="very helpful"
                                                                                    />
                                                                                    <p class="noselect">
                                                                                        <span class="radio_span"></span>
                                                                                        Very
                                                                                        helpful
                                                                                    </p>
                                                                                </label>
                                                                            </fieldset>
                                                                            <fieldset class="radio">
                                                                                <label for="pdi-3">
                                                                                    <input
                                                                                        type="radio"
                                                                                        id="pdi-3"
                                                                                        name="preparing_doctor_interactions"
                                                                                        group=""
                                                                                        value="somewhat helpful"
                                                                                    />
                                                                                    <p class="noselect">
                                                                                        <span class="radio_span"></span>
                                                                                        Somewhat
                                                                                        helpful
                                                                                    </p>
                                                                                </label>
                                                                            </fieldset>
                                                                            <fieldset class="radio">
                                                                                <label for="pdi-4">
                                                                                    <input
                                                                                        type="radio"
                                                                                        id="pdi-4"
                                                                                        name="preparing_doctor_interactions"
                                                                                        group=""
                                                                                        value="not at all helpful"
                                                                                    />
                                                                                    <p class="noselect">
                                                                                        <span class="radio_span"></span>
                                                                                        Not
                                                                                        at
                                                                                        all
                                                                                        helpful
                                                                                    </p>
                                                                                </label>
                                                                            </fieldset>
                                                                            <fieldset class="radio">
                                                                                <label for="pdi-5">
                                                                                    <input
                                                                                        type="radio"
                                                                                        id="pdi-5"
                                                                                        name="preparing_doctor_interactions"
                                                                                        group=""
                                                                                        value="not sure"
                                                                                    />
                                                                                    <p class="noselect">
                                                                                        <span class="radio_span"></span>
                                                                                        Not
                                                                                        sure
                                                                                    </p>
                                                                                </label>
                                                                            </fieldset>
                                                                        </div>
                                                                    )}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div
                                                            className={`question-wrapper error-show ${
                                                                errors1[
                                                                    "knowing_expectations_taking_wakix"
                                                                ]
                                                                    ? "error-show"
                                                                    : ""
                                                            }`}
                                                        >
                                                            <div className="centered-content">
                                                                <Controller
                                                                    control={
                                                                        control1
                                                                    }
                                                                    name="knowing_expectations_taking_wakix"
                                                                    rules={{
                                                                        required: true,
                                                                    }}
                                                                    render={({
                                                                        field: {
                                                                            onChange,
                                                                            value,
                                                                        },
                                                                    }) => (
                                                                        <div
                                                                            class="radio_set"
                                                                            onChange={(
                                                                                e
                                                                            ) =>
                                                                                onChange(
                                                                                    e
                                                                                        .target
                                                                                        .value
                                                                                )
                                                                            }
                                                                        >
                                                                            <h2 class="question">
                                                                                Knowing
                                                                                what
                                                                                to
                                                                                expect
                                                                                when
                                                                                taking
                                                                                WAKIX.
                                                                            </h2>
                                                                            <fieldset class="radio">
                                                                                <label for="ketw-1">
                                                                                    <input
                                                                                        type="radio"
                                                                                        id="ketw-1"
                                                                                        name="knowing_expectations_taking_wakix"
                                                                                        group=""
                                                                                        value="extremely helpful"
                                                                                    />
                                                                                    <p class="noselect">
                                                                                        <span class="radio_span"></span>
                                                                                        Extremely
                                                                                        helpful
                                                                                    </p>
                                                                                </label>
                                                                            </fieldset>
                                                                            <fieldset class="radio">
                                                                                <label for="ketw-2">
                                                                                    <input
                                                                                        type="radio"
                                                                                        id="ketw-2"
                                                                                        name="knowing_expectations_taking_wakix"
                                                                                        group=""
                                                                                        value="very helpful"
                                                                                    />
                                                                                    <p class="noselect">
                                                                                        <span class="radio_span"></span>
                                                                                        Very
                                                                                        helpful
                                                                                    </p>
                                                                                </label>
                                                                            </fieldset>
                                                                            <fieldset class="radio">
                                                                                <label for="ketw-3">
                                                                                    <input
                                                                                        type="radio"
                                                                                        id="ketw-3"
                                                                                        name="knowing_expectations_taking_wakix"
                                                                                        group=""
                                                                                        value="somewhat helpful"
                                                                                    />
                                                                                    <p class="noselect">
                                                                                        <span class="radio_span"></span>
                                                                                        Somewhat
                                                                                        helpful
                                                                                    </p>
                                                                                </label>
                                                                            </fieldset>
                                                                            <fieldset class="radio">
                                                                                <label for="ketw-4">
                                                                                    <input
                                                                                        type="radio"
                                                                                        id="ketw-4"
                                                                                        name="knowing_expectations_taking_wakix"
                                                                                        group=""
                                                                                        value="not at all helpful"
                                                                                    />
                                                                                    <p class="noselect">
                                                                                        <span class="radio_span"></span>
                                                                                        Not
                                                                                        at
                                                                                        all
                                                                                        helpful
                                                                                    </p>
                                                                                </label>
                                                                            </fieldset>
                                                                            <fieldset class="radio">
                                                                                <label for="ketw-5">
                                                                                    <input
                                                                                        type="radio"
                                                                                        id="ketw-5"
                                                                                        name="knowing_expectations_taking_wakix"
                                                                                        group=""
                                                                                        value="not sure"
                                                                                    />
                                                                                    <p class="noselect">
                                                                                        <span class="radio_span"></span>
                                                                                        Not
                                                                                        sure
                                                                                    </p>
                                                                                </label>
                                                                            </fieldset>
                                                                        </div>
                                                                    )}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div
                                                            className={`question-wrapper gray-bg ${
                                                                errors1[
                                                                    "making_daily_routines_taking_wakix"
                                                                ]
                                                                    ? "error-show"
                                                                    : ""
                                                            }`}
                                                        >
                                                            <div className="centered-content">
                                                                <Controller
                                                                    control={
                                                                        control1
                                                                    }
                                                                    name="making_daily_routines_taking_wakix"
                                                                    rules={{
                                                                        required: true,
                                                                    }}
                                                                    render={({
                                                                        field: {
                                                                            onChange,
                                                                            value,
                                                                        },
                                                                    }) => (
                                                                        <div
                                                                            class="radio_set"
                                                                            onChange={(
                                                                                e
                                                                            ) =>
                                                                                onChange(
                                                                                    e
                                                                                        .target
                                                                                        .value
                                                                                )
                                                                            }
                                                                        >
                                                                            <h2 class="question">
                                                                                Making
                                                                                a
                                                                                daily
                                                                                routine
                                                                                for
                                                                                taking
                                                                                WAKIX.
                                                                            </h2>
                                                                            <fieldset class="radio">
                                                                                <label for="mdrtw-1">
                                                                                    <input
                                                                                        type="radio"
                                                                                        id="mdrtw-1"
                                                                                        name="making_daily_routines_taking_wakix"
                                                                                        group=""
                                                                                        value="extremely helpful"
                                                                                    />
                                                                                    <p class="noselect">
                                                                                        <span class="radio_span"></span>
                                                                                        Extremely
                                                                                        helpful
                                                                                    </p>
                                                                                </label>
                                                                            </fieldset>
                                                                            <fieldset class="radio">
                                                                                <label for="mdrtw-2">
                                                                                    <input
                                                                                        type="radio"
                                                                                        id="mdrtw-2"
                                                                                        name="making_daily_routines_taking_wakix"
                                                                                        group=""
                                                                                        value="very helpful"
                                                                                    />
                                                                                    <p class="noselect">
                                                                                        <span class="radio_span"></span>
                                                                                        Very
                                                                                        helpful
                                                                                    </p>
                                                                                </label>
                                                                            </fieldset>
                                                                            <fieldset class="radio">
                                                                                <label for="mdrtw-3">
                                                                                    <input
                                                                                        type="radio"
                                                                                        id="mdrtw-3"
                                                                                        name="making_daily_routines_taking_wakix"
                                                                                        group=""
                                                                                        value="somewhat helpful"
                                                                                    />
                                                                                    <p class="noselect">
                                                                                        <span class="radio_span"></span>
                                                                                        Somewhat
                                                                                        helpful
                                                                                    </p>
                                                                                </label>
                                                                            </fieldset>
                                                                            <fieldset class="radio">
                                                                                <label for="mdrtw-4">
                                                                                    <input
                                                                                        type="radio"
                                                                                        id="mdrtw-4"
                                                                                        name="making_daily_routines_taking_wakix"
                                                                                        group=""
                                                                                        value="not at all helpful"
                                                                                    />
                                                                                    <p class="noselect">
                                                                                        <span class="radio_span"></span>
                                                                                        Not
                                                                                        at
                                                                                        all
                                                                                        helpful
                                                                                    </p>
                                                                                </label>
                                                                            </fieldset>
                                                                            <fieldset class="radio">
                                                                                <label for="mdrtw-5">
                                                                                    <input
                                                                                        type="radio"
                                                                                        id="mdrtw-5"
                                                                                        name="making_daily_routines_taking_wakix"
                                                                                        group=""
                                                                                        value="not sure"
                                                                                    />
                                                                                    <p class="noselect">
                                                                                        <span class="radio_span"></span>
                                                                                        Not
                                                                                        sure
                                                                                    </p>
                                                                                </label>
                                                                            </fieldset>
                                                                        </div>
                                                                    )}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div
                                                            className={`question-wrapper ${
                                                                errors1[
                                                                    "noticing_goal_progression"
                                                                ]
                                                                    ? "error-show"
                                                                    : ""
                                                            }`}
                                                        >
                                                            <div className="centered-content">
                                                                <Controller
                                                                    control={
                                                                        control1
                                                                    }
                                                                    name="noticing_goal_progression"
                                                                    rules={{
                                                                        required: true,
                                                                    }}
                                                                    render={({
                                                                        field: {
                                                                            onChange,
                                                                            value,
                                                                        },
                                                                    }) => (
                                                                        <div
                                                                            class="radio_set"
                                                                            onChange={(
                                                                                e
                                                                            ) =>
                                                                                onChange(
                                                                                    e
                                                                                        .target
                                                                                        .value
                                                                                )
                                                                            }
                                                                        >
                                                                            <h2 class="question">
                                                                                Noticing
                                                                                any
                                                                                progress
                                                                                I
                                                                                may
                                                                                be
                                                                                making
                                                                                toward
                                                                                my
                                                                                treatment
                                                                                goals.
                                                                            </h2>
                                                                            <fieldset class="radio">
                                                                                <label for="ngp-1">
                                                                                    <input
                                                                                        type="radio"
                                                                                        id="ngp-1"
                                                                                        name="noticing_goal_progression"
                                                                                        group=""
                                                                                        value="extremely helpful"
                                                                                    />
                                                                                    <p class="noselect">
                                                                                        <span class="radio_span"></span>
                                                                                        Extremely
                                                                                        helpful
                                                                                    </p>
                                                                                </label>
                                                                            </fieldset>
                                                                            <fieldset class="radio">
                                                                                <label for="ngp-2">
                                                                                    <input
                                                                                        type="radio"
                                                                                        id="ngp-2"
                                                                                        name="noticing_goal_progression"
                                                                                        group=""
                                                                                        value="very helpful"
                                                                                    />
                                                                                    <p class="noselect">
                                                                                        <span class="radio_span"></span>
                                                                                        Very
                                                                                        helpful
                                                                                    </p>
                                                                                </label>
                                                                            </fieldset>
                                                                            <fieldset class="radio">
                                                                                <label for="ngp-3">
                                                                                    <input
                                                                                        type="radio"
                                                                                        id="ngp-3"
                                                                                        name="noticing_goal_progression"
                                                                                        group=""
                                                                                        value="somewhat helpful"
                                                                                    />
                                                                                    <p class="noselect">
                                                                                        <span class="radio_span"></span>
                                                                                        Somewhat
                                                                                        helpful
                                                                                    </p>
                                                                                </label>
                                                                            </fieldset>
                                                                            <fieldset class="radio">
                                                                                <label for="ngp-4">
                                                                                    <input
                                                                                        type="radio"
                                                                                        id="ngp-4"
                                                                                        name="noticing_goal_progression"
                                                                                        group=""
                                                                                        value="not at all helpful"
                                                                                    />
                                                                                    <p class="noselect">
                                                                                        <span class="radio_span"></span>
                                                                                        Not
                                                                                        at
                                                                                        all
                                                                                        helpful
                                                                                    </p>
                                                                                </label>
                                                                            </fieldset>
                                                                            <fieldset class="radio">
                                                                                <label for="ngp-5">
                                                                                    <input
                                                                                        type="radio"
                                                                                        id="ngp-5"
                                                                                        name="noticing_goal_progression"
                                                                                        group=""
                                                                                        value="not sure"
                                                                                    />
                                                                                    <p class="noselect">
                                                                                        <span class="radio_span"></span>
                                                                                        Not
                                                                                        sure
                                                                                    </p>
                                                                                </label>
                                                                            </fieldset>
                                                                        </div>
                                                                    )}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div
                                                            className={`question-wrapper gray-bg ${
                                                                errors1[
                                                                    "finding_more_support_managing_narcolepsy"
                                                                ]
                                                                    ? "error-show"
                                                                    : ""
                                                            }`}
                                                        >
                                                            <div className="centered-content">
                                                                <Controller
                                                                    control={
                                                                        control1
                                                                    }
                                                                    name="finding_more_support_managing_narcolepsy"
                                                                    rules={{
                                                                        required: true,
                                                                    }}
                                                                    render={({
                                                                        field: {
                                                                            onChange,
                                                                            value,
                                                                        },
                                                                    }) => (
                                                                        <div
                                                                            class="radio_set"
                                                                            onChange={(
                                                                                e
                                                                            ) =>
                                                                                onChange(
                                                                                    e
                                                                                        .target
                                                                                        .value
                                                                                )
                                                                            }
                                                                        >
                                                                            <h2 class="question">
                                                                                Finding
                                                                                more
                                                                                information
                                                                                about
                                                                                support
                                                                                options
                                                                                for
                                                                                managing
                                                                                my
                                                                                narcolepsy{" "}
                                                                                <br />{" "}
                                                                                <span>
                                                                                    (eg,
                                                                                    family
                                                                                    and
                                                                                    friends,
                                                                                    support
                                                                                    groups,
                                                                                    healthcare
                                                                                    providers).
                                                                                </span>
                                                                            </h2>
                                                                            <fieldset class="radio">
                                                                                <label for="fmsmn-1">
                                                                                    <input
                                                                                        type="radio"
                                                                                        id="fmsmn-1"
                                                                                        name="finding_more_support_managing_narcolepsy"
                                                                                        group=""
                                                                                        value="extremely helpful"
                                                                                    />
                                                                                    <p class="noselect">
                                                                                        <span class="radio_span"></span>
                                                                                        Extremely
                                                                                        helpful
                                                                                    </p>
                                                                                </label>
                                                                            </fieldset>
                                                                            <fieldset class="radio">
                                                                                <label for="fmsmn-2">
                                                                                    <input
                                                                                        type="radio"
                                                                                        id="fmsmn-2"
                                                                                        name="finding_more_support_managing_narcolepsy"
                                                                                        group=""
                                                                                        value="very helpful"
                                                                                    />
                                                                                    <p class="noselect">
                                                                                        <span class="radio_span"></span>
                                                                                        Very
                                                                                        helpful
                                                                                    </p>
                                                                                </label>
                                                                            </fieldset>
                                                                            <fieldset class="radio">
                                                                                <label for="fmsmn-3">
                                                                                    <input
                                                                                        type="radio"
                                                                                        id="fmsmn-3"
                                                                                        name="finding_more_support_managing_narcolepsy"
                                                                                        group=""
                                                                                        value="somewhat helpful"
                                                                                    />
                                                                                    <p class="noselect">
                                                                                        <span class="radio_span"></span>
                                                                                        Somewhat
                                                                                        helpful
                                                                                    </p>
                                                                                </label>
                                                                            </fieldset>
                                                                            <fieldset class="radio">
                                                                                <label for="fmsmn-4">
                                                                                    <input
                                                                                        type="radio"
                                                                                        id="fmsmn-4"
                                                                                        name="finding_more_support_managing_narcolepsy"
                                                                                        group=""
                                                                                        value="not at all helpful"
                                                                                    />
                                                                                    <p class="noselect">
                                                                                        <span class="radio_span"></span>
                                                                                        Not
                                                                                        at
                                                                                        all
                                                                                        helpful
                                                                                    </p>
                                                                                </label>
                                                                            </fieldset>
                                                                            <fieldset class="radio">
                                                                                <label for="fmsmn-5">
                                                                                    <input
                                                                                        type="radio"
                                                                                        id="fmsmn-5"
                                                                                        name="finding_more_support_managing_narcolepsy"
                                                                                        group=""
                                                                                        value="not sure"
                                                                                    />
                                                                                    <p class="noselect">
                                                                                        <span class="radio_span"></span>
                                                                                        Not
                                                                                        sure
                                                                                    </p>
                                                                                </label>
                                                                            </fieldset>
                                                                        </div>
                                                                    )}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="centered-content">
                                                            <div className="btn-wrap">
                                                                <button
                                                                    class="btn btn-primary"
                                                                    onClick={handleSubmit1(
                                                                        (d) =>
                                                                            handleNextStep(
                                                                                2,
                                                                                d
                                                                            )
                                                                    )}
                                                                >
                                                                    Next
                                                                    <TfiAngleRight />
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>

                                    <section
                                        class={`step step-2 ${
                                            step === 2 ? "current-step" : ""
                                        }`}
                                    >
                                        <div class="hero-copy">
                                            <div className="media">
                                                <img
                                                    src={step1DesktopImg}
                                                    alt="survey"
                                                    className="desktop"
                                                />
                                                <img
                                                    src={step1MobileImg}
                                                    alt="survey"
                                                    className="mobile"
                                                />
                                            </div>
                                        </div>
                                        <div class="wrapper">
                                            <div className="centered-content">
                                                <div className="step-counts">
                                                    <h2>Step 2 of 2</h2>
                                                    <div
                                                        className={`${
                                                            Object.keys(errors2)
                                                                .length > 0
                                                                ? "error-show"
                                                                : ""
                                                        }`}
                                                    >
                                                        <p class="error">
                                                            Please make a
                                                            selection.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="content">
                                                <div class="form-content">
                                                    <div className="form-wrapper">
                                                        <div
                                                            className={`question-wrapper gray-bg`}
                                                        >
                                                            <div className="centered-content">
                                                                <Controller
                                                                    control={
                                                                        control2
                                                                    }
                                                                    name="frequency_receiving_emails_tools"
                                                                    rules={{
                                                                        required: true,
                                                                    }}
                                                                    render={({
                                                                        field: {
                                                                            onChange,
                                                                            value,
                                                                        },
                                                                    }) => (
                                                                        <div
                                                                            class="radio_set rl_frequency"
                                                                            onChange={(
                                                                                e
                                                                            ) =>
                                                                                onChange(
                                                                                    e
                                                                                        .target
                                                                                        .value
                                                                                )
                                                                            }
                                                                        >
                                                                            <h2 class="question">
                                                                                The
                                                                                frequency
                                                                                with
                                                                                which
                                                                                you
                                                                                received
                                                                                emails
                                                                                and
                                                                                tools
                                                                                was:
                                                                            </h2>
                                                                            <fieldset class="radio">
                                                                                <label for="fret-1">
                                                                                    <input
                                                                                        type="radio"
                                                                                        id="fret-1"
                                                                                        name="frequency_receiving_emails_tools"
                                                                                        group=""
                                                                                        value="less often than I would like"
                                                                                    />
                                                                                    <p class="noselect">
                                                                                        <span class="radio_span"></span>
                                                                                        Less
                                                                                        often
                                                                                        than
                                                                                        I
                                                                                        would
                                                                                        like
                                                                                    </p>
                                                                                </label>
                                                                            </fieldset>
                                                                            <fieldset class="radio">
                                                                                <label for="fret-2">
                                                                                    <input
                                                                                        type="radio"
                                                                                        id="fret-2"
                                                                                        name="frequency_receiving_emails_tools"
                                                                                        group=""
                                                                                        value="about right"
                                                                                    />
                                                                                    <p class="noselect">
                                                                                        <span class="radio_span"></span>
                                                                                        About
                                                                                        right
                                                                                    </p>
                                                                                </label>
                                                                            </fieldset>
                                                                            <fieldset class="radio">
                                                                                <label for="fret-3">
                                                                                    <input
                                                                                        type="radio"
                                                                                        id="fret-3"
                                                                                        name="frequency_receiving_emails_tools"
                                                                                        group=""
                                                                                        value="more often than I would like"
                                                                                    />
                                                                                    <p class="noselect">
                                                                                        <span class="radio_span"></span>
                                                                                        More
                                                                                        often
                                                                                        than
                                                                                        I
                                                                                        would
                                                                                        like
                                                                                    </p>
                                                                                </label>
                                                                            </fieldset>
                                                                        </div>
                                                                    )}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="question-wrapper">
                                                            <div className="centered-content">
                                                                <h2 class="question">
                                                                    How likely
                                                                    would you be
                                                                    to{" "}
                                                                    <em>
                                                                        recommend
                                                                    </em>{" "}
                                                                    the emails
                                                                    and tools
                                                                    you received
                                                                    to another
                                                                    person who
                                                                    has been
                                                                    prescribed
                                                                    WAKIX?
                                                                </h2>
                                                                <div class="range-container">
                                                                    <ul class="range-pips">
                                                                        <li></li>
                                                                        <li></li>
                                                                        <li></li>
                                                                        <li></li>
                                                                        <li></li>
                                                                        <li></li>
                                                                        <li></li>
                                                                        <li></li>
                                                                        <li></li>
                                                                        <li></li>
                                                                        <li></li>
                                                                    </ul>
                                                                    <input
                                                                        type="range"
                                                                        id="vol"
                                                                        {...register2(
                                                                            "vol",
                                                                            {
                                                                                required: true,
                                                                            }
                                                                        )}
                                                                        defaultValue="5"
                                                                        min="0"
                                                                        max="10"
                                                                    ></input>
                                                                    <div class="range-labels">
                                                                        <div class="low-range-container">
                                                                            0
                                                                            <br />
                                                                            Very
                                                                            unlikely
                                                                        </div>
                                                                        <div class="slide-label">
                                                                            Slide
                                                                            to
                                                                            Select
                                                                        </div>
                                                                        <div class="high-range-container">
                                                                            10
                                                                            <br />
                                                                            Very
                                                                            likely
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="question-wrapper gray-bg">
                                                            <div className="centered-content">
                                                                <div className="checkbox_set">
                                                                    <h2 className="question">
                                                                        Which of
                                                                        the
                                                                        following
                                                                        features
                                                                        would
                                                                        make the
                                                                        program{" "}
                                                                        <em>
                                                                            more
                                                                            useful
                                                                        </em>
                                                                        ?{" "}
                                                                        <span>
                                                                            &#40;Select
                                                                            all
                                                                            that
                                                                            apply.&#41;
                                                                        </span>
                                                                    </h2>
                                                                    <fieldset className="checkbox_field">
                                                                        <label for="stories_box">
                                                                            <input
                                                                                id="stories_box"
                                                                                type="checkbox"
                                                                                key="stories_box"
                                                                                name="FeaturesBox[]"
                                                                                {...register2(
                                                                                    "FeaturesBox",
                                                                                    {
                                                                                        required: true,
                                                                                    }
                                                                                )}
                                                                                value="stories and experiences from others who are taking wakix"
                                                                                onChange={
                                                                                    handleCheckboxChange
                                                                                }
                                                                                checked={selectedValues.includes(
                                                                                    "stories and experiences from others who are taking wakix"
                                                                                )}
                                                                            />
                                                                            <span className="noselect">
                                                                                Stories
                                                                                and
                                                                                experiences
                                                                                from
                                                                                others
                                                                                who
                                                                                are
                                                                                taking
                                                                                WAKIX
                                                                            </span>
                                                                        </label>
                                                                    </fieldset>

                                                                    <fieldset className="checkbox_field">
                                                                        <label for="options_resources_box">
                                                                            <input
                                                                                id="options_resources_box"
                                                                                key="options_resources_box"
                                                                                type="checkbox"
                                                                                name="FeaturesBox[]"
                                                                                {...register2(
                                                                                    "FeaturesBox",
                                                                                    {
                                                                                        required: true,
                                                                                    }
                                                                                )}
                                                                                value="options to have more resources mailed to me"
                                                                                onChange={
                                                                                    handleCheckboxChange
                                                                                }
                                                                                checked={selectedValues.includes(
                                                                                    "options to have more resources mailed to me"
                                                                                )}
                                                                            />
                                                                            <span className="noselect">
                                                                                Options
                                                                                to
                                                                                have
                                                                                more
                                                                                resources
                                                                                mailed
                                                                                to
                                                                                me
                                                                            </span>
                                                                        </label>
                                                                    </fieldset>

                                                                    <fieldset className="checkbox_field">
                                                                        <label for="online_activities_box">
                                                                            <input
                                                                                id="online_activities_box"
                                                                                key="online_activities_box"
                                                                                type="checkbox"
                                                                                name="FeaturesBox[]"
                                                                                {...register2(
                                                                                    "FeaturesBox",
                                                                                    {
                                                                                        required: true,
                                                                                    }
                                                                                )}
                                                                                value="online interactive learning activities"
                                                                                onChange={
                                                                                    handleCheckboxChange
                                                                                }
                                                                                checked={selectedValues.includes(
                                                                                    "online interactive learning activities"
                                                                                )}
                                                                            />
                                                                            <span className="noselect">
                                                                                Online
                                                                                interactive
                                                                                learning
                                                                                activities
                                                                            </span>
                                                                        </label>
                                                                    </fieldset>

                                                                    <fieldset className="checkbox_field">
                                                                        <label for="text_reminders_box">
                                                                            <input
                                                                                id="text_reminders_box"
                                                                                key="text_reminders_box"
                                                                                type="checkbox"
                                                                                name="FeaturesBox[]"
                                                                                {...register2(
                                                                                    "FeaturesBox",
                                                                                    {
                                                                                        required: true,
                                                                                    }
                                                                                )}
                                                                                value="reminders and tips sent via text message"
                                                                                onChange={
                                                                                    handleCheckboxChange
                                                                                }
                                                                                checked={selectedValues.includes(
                                                                                    "reminders and tips sent via text message"
                                                                                )}
                                                                            />
                                                                            <span className="noselect">
                                                                                Reminders
                                                                                and
                                                                                tips
                                                                                sent
                                                                                via
                                                                                text
                                                                                message
                                                                            </span>
                                                                        </label>
                                                                    </fieldset>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="question-wrapper">
                                                            <div className="centered-content">
                                                                <Controller
                                                                    control={
                                                                        control2
                                                                    }
                                                                    name="taking_wakix"
                                                                    rules={{
                                                                        required: true,
                                                                    }}
                                                                    render={({
                                                                        field: {
                                                                            onChange,
                                                                            value,
                                                                        },
                                                                    }) => (
                                                                        <div
                                                                            class="radio_set"
                                                                            onChange={(
                                                                                e
                                                                            ) =>
                                                                                onChange(
                                                                                    e
                                                                                        .target
                                                                                        .value
                                                                                )
                                                                            }
                                                                        >
                                                                            <h2 class="question">
                                                                                Are
                                                                                you
                                                                                currently
                                                                                taking
                                                                                WAKIX?
                                                                            </h2>
                                                                            <fieldset class="radio">
                                                                                <label for="tw-1">
                                                                                    <input
                                                                                        type="radio"
                                                                                        id="tw-1"
                                                                                        name="taking_wakix"
                                                                                        group=""
                                                                                        value="Yes"
                                                                                    />
                                                                                    <p class="noselect">
                                                                                        <span class="radio_span"></span>
                                                                                        Yes
                                                                                    </p>
                                                                                </label>
                                                                            </fieldset>
                                                                            <fieldset class="radio">
                                                                                <label for="tw-2">
                                                                                    <input
                                                                                        type="radio"
                                                                                        id="tw-2"
                                                                                        name="taking_wakix"
                                                                                        group=""
                                                                                        value="No"
                                                                                    />
                                                                                    <p class="noselect">
                                                                                        <span class="radio_span"></span>
                                                                                        No
                                                                                    </p>
                                                                                </label>
                                                                            </fieldset>
                                                                        </div>
                                                                    )}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="centered-content">
                                                            <div className="btn-wrap">
                                                                <button
                                                                    className="btn btn-primary"
                                                                    onClick={handleSubmit2(
                                                                        (d) =>
                                                                            handleNextStep(
                                                                                3,
                                                                                d
                                                                            )
                                                                    )}
                                                                >
                                                                    Submit
                                                                    <TfiAngleRight />
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>

                                    <section
                                        className={`step step-3 ${
                                            step === 3 ? "current-step" : ""
                                        }`}
                                    >
                                        <div className="centered-content">
                                            <div className="page-content thank-you gray-bg">
                                                <div className="media">
                                                    <img
                                                        src={thankYouIcon}
                                                        className="mobile-header-icon"
                                                        alt="Clipboard icon"
                                                    />
                                                    <div className="text-content">
                                                        <h1 className="font-40">
                                                            Thank You!
                                                        </h1>
                                                        <p>
                                                            Thank you for your
                                                            responses! We
                                                            appreciate your time
                                                            to help us improve
                                                            the program.
                                                        </p>
                                                        <p>
                                                            As you continue to
                                                            manage your EDS or
                                                            cataplexy in
                                                            narcolepsy, we
                                                            encourage you to
                                                            come back to{" "}
                                                            <a href="/">
                                                                <strong>
                                                                    WAKIX.com
                                                                </strong>
                                                            </a>{" "}
                                                            for additional
                                                            support.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </Col>
                        </Row>
                    </section>
                </Col>
            </Row>
        </HcpLayout>
    );
};

export default SurveyPage;

export const Head = () => (
    <Seo
        title="Survey | WAKIX® (pitolisant) tablets"
        description="Wakix Survey"
        keywords="WAKIX support, WAKIX learn more, WAKIX news, WAKIX updates, WAKIX sign up, WAKIX registration, WAKIX program, WAKIX program sign up, WAKIX program registration, pitolisant support, pitolisant learn more, pitolisant news, pitolisant updates"
    />
);
